import React, {useEffect} from "react";
import {useState} from "react";
import useCookie from 'react-use-cookie';
import {Card, TextArea, Select, TextField, IconButton, Link, Text, Snack, Snackbar, Toggle, Spinner, Tooltip} from "@nike/eds";

import {TestPrintForm} from "../model/TestPrintForm";
import {SimulationService} from "../service/SimulationService";

export function TestPrint(props): JSX.Element {

    const simulationService: SimulationService = props.simulationService

    const sourceOptions = [
        {value: "1065", label: "Cortez"},
        {value: "1067", label: "Windrunner"},
        {value: "1093", label: "Impact"},
    ];

    const sourceTypeLabelConfig =
        {
            "1065":
                {
                    "generate": ["CARRIER_LABEL", "INTERNAL_CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "UNIT_LABEL", "BOX_LABEL", "ILPN_LABEL", "BLINDILPN_LABEL", "PICKCARTON_LABEL", "GIFTCARD", "STARTSPUR", "TASKSTART", "TASKSTARTV2", "TASKSTARTV3", "PALLET", "OFFSITE_PALLET"],
                    "print": ["CARRIER_LABEL", "INTERNAL_CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "UNIT_LABEL", "BOX_LABEL", "ILPN_LABEL", "BLINDILPN_LABEL", "PICKCARTON_LABEL", "GIFTCARD", "STARTSPUR", "TASKSTART", "TASKSTARTV2", "TASKSTARTV3", "PALLET"],
                    "generatePrint": ["CARRIER_LABEL", "INTERNAL_CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "UNIT_LABEL", "BOX_LABEL", "ILPN_LABEL", "BLINDILPN_LABEL", "PACKLIST", "PICKCARTON_LABEL", "GIFTCARD", "STARTSPUR", "TASKSTART", "TASKSTARTV2", "TASKSTARTV3", "PALLET", "OFFSITE_PALLET", "UPC_LABEL"],
                    "printCupsAdapter": ["CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "LPN_LABEL", "GENERIC_ZPL", "COLLATE_REPORT", "BOL_REPORT", "TASK_LABEL", "CMR"],
                    "multiPrint": ["OPS_LABEL"],
                },
            "1067":
                {
                    "generate": ["TASK_LABEL", "OPS_LABEL", "UPC_LABEL", "SHOEBOX_LABEL"],
                    "print": ["TASK_LABEL", "OPS_LABEL", "UPC_LABEL", "SHOEBOX_LABEL"],
                    "generatePrint": ["TASK_LABEL", "OPS_LABEL", "UPC_LABEL", "SHOEBOX_LABEL", "UPC_LABEL"],
                    "printCupsAdapter": ["GENERIC_ZPL"],
                },
            "1093":
                {
                    "generate": ["CARRIER_LABEL", "INTERNAL_CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "UNIT_LABEL", "BOX_LABEL", "ILPN_LABEL", "BLINDILPN_LABEL", "MANIFEST", "PICKCARTON_LABEL", "GIFTCARD", "STARTSPUR", "TASKSTART", "TASKSTARTV2", "TASKSTARTV3", "PALLET", "OFFSITE_PALLET"],
                    "print": ["CARRIER_LABEL", "INTERNAL_CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "UNIT_LABEL", "BOX_LABEL", "ILPN_LABEL", "BLINDILPN_LABEL", "MANIFEST", "PICKCARTON_LABEL", "GIFTCARD", "STARTSPUR", "TASKSTART", "TASKSTARTV2", "TASKSTARTV3", "PALLET"],
                    "generatePrint": ["CARRIER_LABEL", "INTERNAL_CARRIER_LABEL", "RETURN_LABEL", "OPS_LABEL", "UNIT_LABEL", "BOX_LABEL", "ILPN_LABEL", "BLINDILPN_LABEL", "MANIFEST", "PACKLIST", "PICKCARTON_LABEL", "GIFTCARD", "STARTSPUR", "TASKSTART", "TASKSTARTV2", "TASKSTARTV3", "PALLET", "OFFSITE_PALLET", "UPC_LABEL"],
                    "printCupsAdapter": ["LPN_LABEL", "GENERIC_ZPL", "COLLATE_REPORT", "BOL_REPORT", "TASK_LABEL", "CMR"],
                },
            "1012":
                {
                    //"generate":[],
                    //"print":[],
                    //"generatePrint":["carrier", "return"],
                    "printCupsAdapter": ["LPN_LABEL", "GENERIC_ZPL", "COLLATE_REPORT", "BOL_REPORT", "TASK_LABEL", "CMR"],
                }
        }

    const documentTypeExtensionsMap = new Map([
        ["LPN_LABEL", "ZPL"],
        ["GENERIC_ZPL", "ZPL"],
        ["COLLATE_REPORT", "PDF"],
        ["BOL_REPORT", "PDF"],
        ["TASK_LABEL", "PDF"],
        ["CMR", "PDF"],
        ["PALLET", "JSON"],
        ["OFFSITE_PALLET", "JSON"],
        ["CARRIER_LABEL", "JSON"],
        ["INTERNAL_CARRIER_LABEL", "JSON"],
        ["RETURN_LABEL", "JSON"],
        ["UNIT_LABEL", "JSON"],
        ["BOX_LABEL", "JSON"],
        ["ILPN_LABEL", "JSON"],
        ["BLINDILPN_LABEL", "JSON"],
        ["PACKLIST", "JSON"],
        ["PICKCARTON_LABEL", "JSON"],
        ["GIFTCARD", "JSON"],
        ["OPS_LABEL", "JSON"],
        ["STARTSPUR", "JSON"],
        ["TASKSTART", "JSON"],
        ["TASKSTARTV2", "JSON"],
        ["TASKSTARTV3", "JSON"],
        ["UPC_LABEL", "JSON"],
        ["MANIFEST", "JSON"],
    ]);

    const allDocumentTypesOptions = [
        {value: "", label: "Select..."},
        {value: "LPN_LABEL", label: "LPN Label"},
        {value: "COLLATE_REPORT", label: "Collate Report"},
        {value: "GENERIC_ZPL", label: "Generic ZPL"},
        {value: "BOL_REPORT", label: "BOL Report"},
        {value: "TASK_LABEL", label: "Task Label"},
        {value: "CMR", label: "CMR"},
        {value: "PALLET", label: "Pallet"},
        {value: "CARRIER_LABEL", label: "Carrier Label"},
        {value: "INTERNAL_CARRIER_LABEL", label: "Internal carrier Label"},
        {value: "RETURN_LABEL", label: "Return Label"},
        {value: "BOX_LABEL", label: "Box Label"},
        {value: "UNIT_LABEL", label: "Unit Label"},
        {value: "ILPN_LABEL", label: "ILPN Label"},
        {value: "BLINDILPN_LABEL", label: "Blind ILPN Label"},
        {value: "PACKLIST", label: "Packlist"},
        {value: "PICKCARTON_LABEL", label: "Pickcarton Label"},
        {value: "GIFTCARD", label: "Gift Card"},
        {value: "OPS_LABEL", label: "OPS Label"},
        {value: "STARTSPUR", label: "Start Spur Label"},
        {value: "TASKSTART", label: "Taskstart Label"},
        {value: "TASKSTARTV2", label: "Taskstart Label V2"},
        {value: "TASKSTARTV3", label: "Taskstart Label V3"},
        {value: "OFFSITE_PALLET", label: "Offsite Pallet Label"},
        {value: "UPC_LABEL", label: "UPC Label"},
        {value: "MANIFEST", label: "Manifest"},
    ];

    const contentTypesOptions = [
        {value: "ipp", label: "application/ipp"},
        {value: "rest", label: "application/rest"},
        {value: "multipart", label: "form/multipart"}
    ];

    const allRequestTypeOptions = [
        {value: "", label: "Select..."},
        {
            value: "generate",
            label: "Generate",
            "showPrinter": false,
            "showRequestContentType": false,
            "showFileInput": false,
            "showGroupRequestInput": false,
        },
        {
            value: "print",
            label: "Print",
            "showPrinter": false,
            "showRequestContentType": false,
            "showFileInput": false,
            "showGroupRequestInput": false,
        },
        {
            value: "generatePrint",
            label: "Generate & Print",
            "showPrinter": false,
            "showRequestContentType": false,
            "showFileInput": false,
            "showGroupRequestInput": false,
        },
        {
            value: "multiPrint",
            label: "Multi Print",
            "showPrinter": false,
            "showRequestContentType": false,
            "showFileInput": false,
            "showGroupRequestInput": false,
        },
        {
            value: "printCupsAdapter",
            label: "Print (Cups Adapter)",
            "showPrinter": true,
            "showRequestContentType": true,
            "showFileInput": true,
            "showGroupRequestInput": true,
        },
    ];

    const [source, setSource] = useCookie('source', '');
    const [documentType, setDocumentType] = useState('');
    const [documentTypesOptions, setDocumentTypesOptions] = useState(allDocumentTypesOptions);
    const [contentType, setContentType] = useState(contentTypesOptions.length === 1 ? contentTypesOptions[0].value : '');
    const [requestTypeOptions, setRequestTypeOptions] = useState(allRequestTypeOptions);
    const [requestType, setRequestType] = useState('');
    const [payloadContent, setPayloadContent] = useState('');
    const [fileContent, setFileContent] = useState<File | undefined>(undefined);
    const [isFileInput, setIsFileInput] = useState(false);
    const [uploadingFile, setUploadingFile] = useState(false);
    const [loading, setLoading] = useState(false);
    const [printerName, setPrinterName] = useState('');
    const [sourceFieldHasErrors, setSourceFieldHasErrors] = useState(false);
    const [printerFieldHasErrors, setPrinterFieldHasErrors] = useState(false);
    const [documentTypeFieldHasErrors, setDocumentTypeFieldHasErrors] = useState(false);
    const [returnMessage, setReturnMessage] = useState('');
    const [snackRequestIds, setSnackRequestIds] = useState<string[]>([]);
    const [snackTraceId, setSnackTraceId] = useState<string>('');
    const [showSnackSuccess, setShowSnackSuccess] = useState(false);
    const [showSnackError, setShowSnackError] = useState(false);
    const [groupRequests, setGroupRequests] = useState<TestPrintForm[]>([]);

    useEffect(() => {
        updatePayload();
        updateRequestTypeOptions(source)
    }, [source, documentType, requestType, contentType]); // eslint-disable-line react-hooks/exhaustive-deps

    const sourceChangeHandler = (event) => {
        setRequestType('')
        setDocumentType('')
        updateRequestTypeOptions(event.value);
        setSource(event.value)
    };

    const updateRequestTypeOptions = (source) => {
        if (source && sourceTypeLabelConfig[source]) {
            setRequestTypeOptions(allRequestTypeOptions.filter(option => (Object.keys(sourceTypeLabelConfig[source])).indexOf(option.value) > -1));
        }
    }

    const documentTypeChangeHandler = (event) => {
        if (documentTypeExtensionsMap.get(event.value) === "PDF") {
            setIsFileInput(true);
            setPayloadContent("");
        } else {
            setIsFileInput(false);
            setFileContent(undefined);
        }
        setDocumentType(event.value);
    };

    const contentTypeChangeHandler = (event) => {
        setContentType(event.value);
        endGroupRequest();
    };

    const updateLabelTypeOptions = (requestType) => {
        setDocumentTypesOptions(allDocumentTypesOptions.filter(option => (Object.values(sourceTypeLabelConfig[source][requestType])).indexOf(option.value) > -1));
    }

    const requestTypeChangeHandler = (event) => {
        setDocumentType('')
        updateLabelTypeOptions(event.value)
        setRequestType(event.value)
        endGroupRequest();
    }
    const payloadContentChangeHandler = (event) => {
        setPayloadContent(event.target.value)
    }
    const fileContentChangeHandler = (event) => {
        setFileContent(event.target.files[0])
    }

    const toggleChangeHandler = (event) => {
        if (!isFileInput) {
            setPayloadContent("")
        } else {
            setFileContent(undefined);
        }
        setIsFileInput(!isFileInput)
    }
    const printerNameChangeHandler = (event) => {
        setPrinterName(event.target.value)
        endGroupRequest();
    }

    const validateForm = () => {
        setSourceFieldHasErrors(source === '');
        setPrinterFieldHasErrors(printerName === '' && allRequestTypeOptions.find(e => e.value === requestType)?.showPrinter!);
        setDocumentTypeFieldHasErrors(documentType === '');
        return (documentType !== '' && source !== '' && (printerName !== '' || !allRequestTypeOptions.find(e => e.value === requestType)?.showPrinter!));
    }
    const submitHandler = (event) => {
        event.preventDefault();
        setLoading(true)
        let testPrintData: TestPrintForm[] = [];
        if (groupRequests.length > 0) {
            testPrintData = groupRequests;
            endGroupRequest();
        } else {
            if (validateForm()) {
                testPrintData = [getTestPrintForm()];
            }
        }
        if (testPrintData.length > 0) {
            setReturnMessage("")
            console.log(testPrintData)
            simulationService.startSimulate(testPrintData)
                .then(response => {
                    response.text().then(text => extractSnackIds(text))
                    if (response.status === 200) {
                        setShowSnackSuccess(true)
                    } else {
                        setShowSnackError(true)
                    }
                })
                .catch(e => {
                    setShowSnackError(true)
                    setReturnMessage(e)
                })
                .finally(() => setLoading(false))
        }
    }

    function extractSnackIds(response) {
        setSnackRequestIds([]);
        setSnackTraceId("");
        try {
            let jsonResponse = JSON.parse(response);
            setReturnMessage(jsonResponse.externalResponse);
            if (Array.isArray(jsonResponse.messageIdentifiers) && jsonResponse.messageIdentifiers.length > 0) {
                setSnackRequestIds(jsonResponse.messageIdentifiers as string[]);
            } else if (jsonResponse.messageIdentifier) {
                setSnackRequestIds([jsonResponse.messageIdentifier]);
            } else if (jsonResponse.traceId) {
                setSnackTraceId(jsonResponse.traceId);
            }
        } catch (e) {
            setReturnMessage(response);
        }
    }

    function updatePayload() {
        let testPrintData: TestPrintForm = getTestPrintForm();
        if (testPrintData.source && testPrintData.documentType) {
            setUploadingFile(true);
            simulationService.getPayload(getTestPrintForm()).then((response) => {
                setUploadingFile(false);
                console.log(response)
                if (response.ok) {
                    if (documentTypeExtensionsMap.get(documentType) === "PDF") {
                        response.blob().then((blob) => {
                            const file = new File([blob], documentType + ".pdf", {type: "application/pdf",});
                            const inputField = document.getElementById("fileContent") as HTMLInputElement;
                            if (inputField && inputField.files) {
                                const dataTransfer = new DataTransfer();
                                dataTransfer.items.add(file);
                                inputField.files = dataTransfer.files;
                                setFileContent(file);
                            }
                        });
                    } else {
                        response.text().then((body) => {
                            setPayloadContent(body);
                        });
                    }
                }
            });
        }
    }

    function getTestPrintForm(): TestPrintForm {
        console.log("Getting payload")
        let testPrintData: TestPrintForm = {
            source: source,
            documentType: documentType,
            contentType: contentType,
            requestType: requestType,
            payloadContent: payloadContent,
            printerName: printerName,
            fileContent: fileContent,
            isFileInput: isFileInput
        }
        return testPrintData;
    }

    function addPrintRequest() {
        if (validateForm()) {
            let testPrintData: TestPrintForm = getTestPrintForm();
            setGroupRequests(prevPrintRequests => [...prevPrintRequests, testPrintData]);
        }
    }

    function endGroupRequest() {
        setGroupRequests([]);
    }

    return (
        <div>
            <form onSubmit={submitHandler}>
                <Card>
                    <div className="eds-grid eds-grid--m-cols-6 eds-gap--16">
                        <div className="searchField eds-grid--m-row-1">
                            <Select onChange={sourceChangeHandler}
                                    id="sourceSelect"
                                    options={sourceOptions}
                                    value={sourceOptions.find(e => e.value === source)}
                                    label={sourceFieldHasErrors ? "Source" : "Source *"}
                                    hasErrors={sourceFieldHasErrors}
                                    errorMessage="You haven't made a choice"
                            />
                        </div>

                        <div className="searchField eds-grid--m-row-1">
                            <Select onChange={requestTypeChangeHandler}
                                    id="requestTypeSelect"
                                    options={requestTypeOptions}
                                    value={allRequestTypeOptions.find(e => e.value === requestType)}
                                    label="Request type"
                                    errorMessage="You haven't made a choice"
                                    isDisabled={!source}
                            />
                        </div>

                        <div className="searchField eds-grid--m-row-1" style={{minWidth: 300}}>
                            <Select onChange={documentTypeChangeHandler}
                                    id="documentTypeSelect"
                                    options={documentTypesOptions}
                                    value={allDocumentTypesOptions.find(e => e.value === documentType)}
                                    label="Label type"
                                    hasErrors={documentTypeFieldHasErrors}
                                    errorMessage="You haven't made a choice"
                                    isCreatable={true}
                                    isDisabled={!source || !requestType}
                            />
                        </div>
                        <div
                            className="eds-grid--m-col-3 eds-grid--m-row-1 eds-spacing--p-12 eds-grid--item-align-start eds-grid--item-justify-end"
                            style={{"display": "flex"}}>
                            {allRequestTypeOptions.find(o => o.value === requestType)?.showGroupRequestInput
                                && (contentType === "rest" || contentType === "multipart")
                                && (<>
                                        {groupRequests.length > 0 &&
                                            <Tooltip
                                                bodySlot={groupRequests.map((e, i) => <Text key={i} as={"p"} font={"body-2"}>{e.documentType}</Text>)}
                                                placement={"left-start"}
                                                enableFocus
                                            >
                                                <IconButton
                                                    icon="Delete"
                                                    onClick={() => endGroupRequest()}
                                                    label=""
                                                    variant="ghost"
                                                    style={{marginRight: "5px"}}
                                                    count={groupRequests.length}
                                                    size={"small"}/>
                                            </Tooltip>
                                        }
                                        <IconButton
                                            icon="Plus"
                                            onClick={() => addPrintRequest()}
                                            size={"small"}
                                            style={{marginRight: "5px"}}
                                            label={""}/>
                                    </>
                                )}
                            {loading ?
                                <div style={{
                                    padding: '6px',
                                    backgroundColor: 'gray',
                                    borderRadius: '50%',
                                    marginRight: "5px"
                                }} className="eds--dark">
                                    <Spinner color="white"/>
                                </div>
                                :
                                <IconButton
                                    icon="Play"
                                    type={"submit"}
                                    size={"small"}
                                    style={{marginRight: "5px"}}
                                    label={""}/>
                            }
                            <IconButton
                                onClick={() => window.location.reload()}
                                icon="Undo"
                                size={"small"}
                                style={{marginRight: "5px"}}
                                label={""}/>
                        </div>
                        <div className="searchField eds-grid--m-row-1">
                            <TextField onChange={printerNameChangeHandler}
                                       id="printerField"
                                       type="text"
                                       label={printerFieldHasErrors ? "Printer" : "Printer *"}
                                       hasErrors={printerFieldHasErrors}
                                       errorMessage="You haven't made a choice"
                                       disabled={!allRequestTypeOptions.find(e => e.value === requestType)?.showPrinter}
                            />
                        </div>
                        <div className="searchField eds-grid--m-row-1">
                            <Select onChange={contentTypeChangeHandler}
                                    id="contentTypeSelect"
                                    options={contentTypesOptions}
                                    defaultValue={contentTypesOptions.length === 1 ? contentTypesOptions[0] : ''}
                                    label="Request content type"
                                    errorMessage="You haven't made a choice"
                                    isDisabled={!allRequestTypeOptions.find(e => e.value === requestType)?.showRequestContentType}
                            />
                        </div>
                        <div className="eds-grid--m-row-1 eds-grid--item-align-center">
                            <Toggle style={{bottom: 0}}
                                    id="toggleId"
                                    label="File input"
                                    onChange={toggleChangeHandler}
                                    checked={isFileInput}
                                    disabled={!allRequestTypeOptions.find(e => e.value === requestType)?.showFileInput}
                            />
                        </div>
                    </div>

                    <br/>

                    <div hidden={isFileInput}>
                        <TextArea onChange={payloadContentChangeHandler}
                                  value={payloadContent}
                                  id="payloadContent"
                                  label=""
                                  placeholder="ZPL or JSON content / Leave empty to print dummy pdf"
                                  minRows={25}
                                  autoResize={true}
                        />
                    </div>

                    <div hidden={!isFileInput}>
                        <label className="eds-label eds-type--title-6" htmlFor="fileContent">File input field <Spinner
                            hidden={!uploadingFile}/></label>
                        <TextField onChange={fileContentChangeHandler}
                                   type="file"
                                   id="fileContent"
                                   label=""
                                   accept="application/pdf,application/vnd.ms-excel,.zpl,.pdf"
                        />
                    </div>
                </Card>

                <Snackbar>
                    {showSnackSuccess && (
                        <Snack id="success" status={"success"} onDismiss={id => setShowSnackSuccess(false)}>
                            <Text as={"p"} font={"body-2"}>Simulation succesfully processed.</Text>
                            {snackRequestIds.map((id, i) =>
                                <Text key={i} as={"p"} font={"body-2"}><Link id="printRequest" href={"/request-overview/details/" + id}>Go to print request.</Link></Text>)}
                        </Snack>
                    )}
                    {showSnackError && (
                        <Snack id="error" status={"error"} onDismiss={id => setShowSnackError(false)}>
                            <Text as={"p"} font={"body-2"}>Simulation failed with error:</Text>
                            <Text as={"p"} font={"body-2"} style={{color: 'red'}}>{returnMessage}</Text>
                            {Array.isArray(snackRequestIds) && snackRequestIds.map((id, i) =>
                                <Text key={i} as={"p"} font={"body-2"}><Link id="printRequest" href={"/request-overview/details/" + id}>Go to print request.</Link></Text>)}
                            {snackTraceId !== "" && <Text as={"p"} font={"body-2"}><Link id="traceId" href={"https://app.signalfx.com/#/apm/traces/" + snackTraceId}>Go to trace.</Link></Text>}
                        </Snack>
                    )}
                </Snackbar>

            </form>
        </div>
    )
        ;
}
